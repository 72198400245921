export const dimensionArr = [
  {
    name: '应用',
    id: 'appName',
    key: 1
  },
  {
    name: '广告样式',
    id: 'positionName',
    key: 2
  },
  {
    name: '广告位',
    id: 'placeName',
    key: 3
  },
  {
    name: '流量分组',
    id: 'groupName',
    key: 4
  },
  {
    name: '渠道',
    id: 'channel',
    key: 5
  },
  {
    name: '广告平台',
    id: 'platName',
    key: 6
  },
  {
    name: '广告源',
    id: 'sourceName',
    key: 7
  },
  {
    name: 'SDK版本',
    id: 'sdkVersion',
    key: 8
  },
  {
    name: '应用版本',
    id: 'appVersion',
    key: 9
  }
]
export const indicatorObj = {
  normal: {
    name: '',
    items: [
      {
        data: [],
        name: 'DAU',
        id: 'dau',
        show: true,
        hidden: [6, 7]
      },
      {
        data: [],
        name: 'DEU',
        id: 'deu',
        show: true,
        hidden: [6, 7]
      },
      {
        data: [],
        name: '渗透率',
        id: 'permeability',
        show: true,
        hidden: [6, 7]
      },
      {
        data: [],
        name: '新用户',
        id: 'newLogin',
        show: true,
        hidden: [2, 3, 4, 6, 7]
      },
      {
        data: [],
        name: '新用户占比',
        id: 'newUserRate',
        show: true,
        hidden: [2, 3, 4, 6, 7]
      },
      {
        data: [],
        name: '流量请求',
        id: 'request',
        show: true,
        hidden: [6, 7]
      },
      {
        data: [],
        name: '人均请求',
        id: 'requestDau',
        show: true,
        hidden: [6, 7]
      },
      {
        data: [],
        name: '流量填充率',
        id: 'requestFilledRate',
        show: true,
        hidden: [6, 7]
      }
    ]
  },
  impress: {
    name: '',
    items: [
      {
        data: [],
        name: '展示',
        id: 'impress',
        show: true,
        hidden: []
      },
      {
        data: [],
        name: '展示率',
        id: 'impressRate',
        show: true,
        hidden: []
      },
      {
        data: [],
        name: '展示/DAU',
        id: 'aipau',
        show: true,
        hidden: [6, 7]
      },
      {
        data: [],
        name: '展示/DEU',
        id: 'aipu',
        show: true,
        hidden: [6, 7]
      },
      {
        data: [],
        name: '展示API',
        id: 'unitImpression',
        show: true,
        hidden: [8, 9]
      },
      {
        data: [],
        name: '展示Gap',
        id: 'impressionGap',
        show: true,
        hidden: [8, 9]
      },
      {
        data: [],
        name: '展请率',
        id: 'impressRequestRate',
        show: true,
        hidden: []
      },
      {
        data: [],
        name: '展示占比',
        id: 'display',
        show: true,
        hidden: []
      }
    ]
  },
  click: {
    name: '',
    items: [
      {
        data: [],
        name: '点击',
        id: 'click',
        show: true,
        hidden: []
      },
      {
        data: [],
        name: '人均点击',
        id: 'clickDau',
        show: true,
        hidden: []
      },
      {
        data: [],
        name: '点击率',
        id: 'clickRate',
        show: true,
        hidden: []
      },
      {
        data: [],
        name: '点击API',
        id: 'unitClick',
        show: true,
        hidden: [8, 9]
      },
      {
        data: [],
        name: '点击率API',
        id: 'unitCtr',
        show: true,
        hidden: [8, 9]
      },
      {
        data: [],
        name: '点击Gap',
        id: 'clickGap',
        show: true,
        hidden: [8, 9]
      }
    ]
  },
  income: {
    name: '',
    items: [
      {
        data: [],
        name: '预估收益',
        id: 'income',
        show: true,
        hidden: []
      },
      {
        data: [],
        name: '预估eCPM',
        id: 'estimatedRevenueEcpm',
        show: true,
        hidden: []
      },
      {
        data: [],
        name: 'eCPC',
        id: 'unitEcpc',
        show: true,
        hidden: []
      },
      {
        data: [],
        name: 'eCPR',
        id: 'unitEcpr',
        show: true,
        hidden: []
      },
      {
        data: [],
        name: 'ARPDAU',
        id: 'arpu',
        show: true,
        hidden: [4, 5, 6, 7]
      },
      {
        data: [],
        name: 'ARPDEU',
        id: 'arpDeu',
        show: true,
        hidden: [4, 5, 6, 7]
      },
      {
        data: [],
        name: 'eCPM API',
        id: 'unitEcpm',
        show: true,
        hidden: [8, 9]
      },
      {
        data: [],
        name: '收益API',
        id: 'unitRevenue',
        show: true,
        hidden: [8, 9]
      },
      {
        data: [],
        name: '预估收益占比',
        id: 'revenuePercentage',
        show: true,
        hidden: []
      }
    ]
  }
}

export const options = {
  tooltip: {
    trigger: 'axis',
    formatter: (params) => {
      let str = ''
      params.forEach((item, index) => {
        const temp = {}
        if (
          item.seriesName === '收益' ||
          item.seriesName === 'eCPM' ||
          item.seriesName === '预估收益' ||
          item.seriesName === 'ARPDAU'
        ) {
          temp.value = '￥' + item.value
        } else {
          temp.value = item.value
        }
        str += item.marker + item.seriesName + ': ' + temp.value + '<br>'
      })
      return params[0].axisValue + '<br>' + str
    }
  },
  legend: {
    data: []
  },
  grid: {
    left: '3%',
    right: '7%',
    bottom: '4%',
    containLabel: true
  },
  xAxis: {
    type: 'category',
    data: [],
    boundaryGap: false,
    axisLabel: {
      show: true
    }
  },
  yAxis: [
    // 0
    {
      type: 'value',
      name: '',
      position: 'left',
      axisLine: {
        show: false,
        lineStyle: {
          color: '#666',
          width: 0.5
        }
      },
      axisTick: {
        show: true
      },
      splitLine: {
        show: true
      },

      axisLabel: {
        show: true
      }
    }
  ],
  series: [
    {
      name: '',
      type: 'line',
      smooth: true,
      yAxisIndex: 0,
      data: [],
      symbol: 'circle', // 设定为实心点
      symbolSize: 6, // 设定实心点的大小
      itemStyle: {
        normal: {
          color: '#ffab31',
          lineStyle: {
            color: '#ffab31',
            width: 2
          }
        }
      }
    }
  ]
}
